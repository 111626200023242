import React, { useState, useEffect, useContext } from "react";
import "./styles.css";
import { db } from "../../services/conexaoFirebase";
import { collection, getDocs } from "firebase/firestore";
import { Context } from "../../Context/Auth";

import Header from "../../components/Header/Header";

export default function Painel() {
  const { logOut } = useContext(Context);

  async function deslogar() {
    await logOut();
  }

  return (
    <>
      {/* <div className="areaBotaoDesconectar">
        <button onClick={deslogar} className="btnDesconectar">
          Desconectar!
        </button>
      </div> */}

      <div className="container">
        <div className="headerWrapper">
          <Header />
        </div>
        <div className="content"></div>
      </div>
    </>
  );
}

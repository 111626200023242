import { Link } from "react-router-dom";
import "./styles.css";

import Logo from "../../assets/logo.png";

export default function Header() {
  return (
    <div>
      <header className="header">
        <div className="containerLogo">
          <div className="boxLogoHeader ">
            <img src={Logo} className="logoHeader" alt="logo" />
          </div>
        </div>

        <ul>
          <li>
            <Link to="/criar-categoria">Criar categoria</Link>
          </li>
          <li>
            <Link to="/publicar">Adicionar publicação</Link>
          </li>
          <li>
            <Link to="/publicacoes">Publicações</Link>
          </li>
        </ul>
      </header>
    </div>
  );
}

import { Link } from "react-router-dom";
import "./styles.css";
import Header from "../../components/Header/Header";

export default function ScreenPublicacoes() {
  return (
    <div className="container">
      <div className="headerWrapper">
        <Header />
      </div>
      <div className="content">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <h1>Em breve</h1>
          <p>Planejamento pra Janeiro</p>
        </div>
      </div>
    </div>
  );
}

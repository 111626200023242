import React, { useState, useContext } from "react";
import "./styles.css";
import { Context } from "../Context/Auth";
import { toast } from "react-toastify";
import { MdVerified } from "react-icons/md";
import { FiShare } from "react-icons/fi";

import logoFundoBranco from "../assets/logo-fundo-branco.png";
import MarcaDgua from "../assets/marca_dgua.png";

export default function AdicionarConteudo() {
  const { categorias, adicionarConteudo } = useContext(Context);

  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(
    categorias.length > 0 ? categorias[0].nomeCategoria : ""
  );

  const [caracteresContados, setCaracteresContados] = useState(0);

  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [modeloSelecionado, setModeloSelecionado] = useState("Salmos");

  const handleAdicionar = async (e) => {
    e.preventDefault();

    if (texto.trim() === "" || categoriaSelecionada === "") {
      toast.warning("Por favor, preencha todos os campos!");
      return;
    }

    try {
      // Substituir quebras de linha por espaços vazios
      const textoSemQuebrasDeLinha = texto.replace(/(\r\n|\n|\r)/gm, " ");

      // Chame a função adicionarCategoria do contexto, passando...
      await adicionarConteudo(
        categoriaSelecionada,
        titulo.trim(),
        textoSemQuebrasDeLinha.trim(),
        imageUrl.trim()
      );

      setTitulo("");
      setTexto("");
      setImageUrl("");
      setPreviewImageUrl("");
      setModeloSelecionado("Salmos");

      toast.success("Item adicionado!");
    } catch (error) {
      console.error("Erro ao adicionar item:", error);
      toast.error("Erro ao adicionar item. Por favor, tente novamente.");
    }
  };

  const handleImageUrlChange = (e) => {
    const url = e.target.value;
    setImageUrl(url);
    setPreviewImageUrl(url); // Atualizar a pré-visualização da imagem ao alterar a URL
  };

  const handleTextoChange = (e) => {
    const textoAtual = e.target.value;
    setTexto(textoAtual);
    const caracteres = textoAtual.length;
    setCaracteresContados(caracteres);
  };

  return (
    <div className="containerAdicionarConteudo">
      <form className="form formSmall">
        <h2 className="title">Adicionar conteúdo</h2>

        <div className="boxInput">
          <label>Selecione uma categoria</label>
          <select
            value={categoriaSelecionada}
            onChange={(e) => setCategoriaSelecionada(e.target.value)}
          >
            {/* Mapeie as categorias para criar as opções do select */}
            {categorias.map((categoria) => (
              <option
                key={categoria.nomeCategoria}
                value={categoria.nomeCategoria}
              >
                {categoria.nomeCategoria}
              </option>
            ))}
          </select>
        </div>

        <div className="boxInput">
          <label>Título</label>
          <input
            placeholder="Título"
            maxLength={60}
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
          />
        </div>

        <div className="boxInput">
          <label>Texto</label>
          <textarea
            placeholder="Texto da publicação"
            maxLength={
              categoriaSelecionada === "Poesia para Jesus"
                ? 110
                : 500 && categoriaSelecionada === "Salmos"
                ? 500
                : 500 && categoriaSelecionada === "Minutos de Sabedoria"
                ? 340
                : 500 && categoriaSelecionada === "Mensagens do Dia"
                ? 310
                : 500
            }
            value={texto}
            onChange={handleTextoChange}
            // style={{ whiteSpace: "pre-wrap" }}
          ></textarea>
          <span>{`${caracteresContados}/${
            categoriaSelecionada === "Poesia para Jesus"
              ? 110
              : 500 && categoriaSelecionada === "Salmos"
              ? 500
              : 500 && categoriaSelecionada === "Minutos de Sabedoria"
              ? 340
              : 500 && categoriaSelecionada === "Mensagens do Dia"
              ? 310
              : 500
          }`}</span>
          <br />
        </div>

        <div className="boxInput">
          <label>Url da imagem</label>
          <input
            placeholder="Url da imagem"
            maxLength={900}
            value={imageUrl}
            onChange={handleImageUrlChange}
          />
        </div>

        <button className="buttonForm" onClick={handleAdicionar}>
          Adicionar
        </button>
      </form>

      <div className="containerAreaPreview">
        <h2 className="title">Pré-visualização</h2>
        <div className="containerPreViewImage">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
            }}
          >
            <div className="boxInput">
              <strong>Selecione um modelo</strong>
              <select
                value={modeloSelecionado}
                onChange={(e) => setModeloSelecionado(e.target.value)}
              >
                <option>Salmos</option>
                <option>Minutos de Sabedoria</option>
                <option>Mensagens do dia</option>
                <option>Poesia para Jesus</option>
              </select>
            </div>
          </div>

          {/* Estrutura condicional para renderizar a div correspondente ao modelo selecionado */}
          {modeloSelecionado === "Salmos" && (
            <div className="modelo1">
              <h3>Salmos</h3>

              <div className="previewImage">
                <div className="wrapperModelo">
                  <div className="cardModelo1">
                    <div className="headerLogoENome">
                      <div className="boxLogo">
                        <img src={logoFundoBranco} alt="logo" />
                      </div>
                      <div className="nomeEVerificado">
                        <span>amem.app</span>
                        <MdVerified size={22} color="#699BF7" />
                      </div>
                    </div>
                    <div className="cardInfor">
                      <h4>{titulo ? titulo : "Título de Salmos"}</h4>
                      <p className="sizeTextoModelo1">
                        {texto
                          ? texto
                          : "Aqui ficará o texto que você escrever..."}
                      </p>
                    </div>
                  </div>
                </div>
                {previewImageUrl && (
                  <>
                    <img src={previewImageUrl} alt="imagem de visualização" />
                  </>
                )}
              </div>
            </div>
          )}
          {modeloSelecionado === "Minutos de Sabedoria" && (
            <div className="modelo2">
              <h3>Minutos de sabedoria</h3>

              <div className="">
                <div className="previewImage">
                  {previewImageUrl && (
                    <>
                      <img src={previewImageUrl} alt="imagem de visualização" />
                    </>
                  )}

                  <div className="containerTextosPreviewModelo2">
                    <div className="headerLogoENomeModelo2">
                      <div className="boxLogo">
                        <img src={logoFundoBranco} alt="logo" />
                      </div>
                      <div className="nomeEVerificado">
                        <span>amem.app</span>
                        <MdVerified size={22} color="#699BF7" />
                      </div>
                    </div>
                    <h4>{titulo ? titulo : "Miutos de sabedoria"}</h4>
                    <p className="textoModelo2">{texto}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {modeloSelecionado === "Mensagens do dia" && (
            <div className="modelo3">
              <h3>Mensagens do dia</h3>

              <div className="">
                <div className="previewImageMensagensDoDia">
                  {previewImageUrl && (
                    <>
                      <img src={previewImageUrl} alt="imagem de visualização" />
                    </>
                  )}
                  <div className="containerTextosPreviewModelo3">
                    <h4>{titulo}</h4>
                    <p>{texto}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {modeloSelecionado === "Poesia para Jesus" && (
            <div className="modelo4">
              <h3>Poesia para Jesus</h3>

              <div className="modelo4">
                <div className="previewImagePoesiaParaJesus">
                  {previewImageUrl && (
                    <>
                      <img src={previewImageUrl} alt="imagem de visualização" />
                    </>
                  )}
                  <div className="containerTextoModelo4">
                    <p>{texto}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

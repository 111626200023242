import React, { createContext, useState, useEffect } from "react";
import { auth, db } from "../services/conexaoFirebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc, setDoc, collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const Context = createContext({});

export default function ContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const [categorias, setCategorias] = useState([]);

  const navigate = useNavigate();

  async function logar(email, senha) {
    setLoadingAuth(true);

    await signInWithEmailAndPassword(auth, email, senha)
      .then(async (value) => {
        let uid = value.user.uid;

        const docRef = doc(db, "admins", uid);
        const docDados = await getDoc(docRef);

        if (docDados.exists()) {
          let data = {
            uid: uid,
            email: value.user.email,
          };

          setUser(data);
          storageUser(data);
          setLoadingAuth(false);
          toast.success("Bem-Vindo(a) de volta!");
          navigate("/painel");
        } else {
          toast.error("Apenas administradores podem fazer login.");
          setLoadingAuth(false);
        }
      })
      .catch((error) => {
        toast.error("Oops! Algo deu errado, " + error);
        console.log(error);
        setLoadingAuth(false);
      });
  }

  function storageUser(data) {
    localStorage.setItem("@usuarios_salvos", JSON.stringify(data));
  }

  async function logOut() {
    await signOut(auth)
      .then(() => {
        toast.success("Você foi desconectado com sucesso!");
        navigate("/");
      })
      .catch((error) => {
        toast.success(error);
      });
    localStorage.removeItem("@usuarios_salvos");
    setUser(null);
  }

  // criar categoria
  const adicionarCategoria = async (nomeCategoria) => {
    try {
      // Referência ao documento "categorias" dentro da coleção "frases"
      const categoriaRef = doc(db, "frases", "categorias");

      // Obtém os dados atuais do documento
      const categoriaDoc = await getDoc(categoriaRef);

      if (categoriaDoc.exists()) {
        // Obtenha os dados atuais
        const categoriasData = categoriaDoc.data();

        // Adicione a nova categoria
        categoriasData[nomeCategoria] = [];

        // Atualize os dados no Firestore
        await setDoc(categoriaRef, categoriasData);

        // Atualize o estado local, se necessário
        // Isso depende da lógica específica do seu aplicativo
      }
    } catch (error) {
      console.error("Erro ao adicionar categoria:", error);
      // Trate o erro conforme necessário
    }
  };

  // adicionar conteúdo dentro da categoria
  const adicionarConteudo = async (nomeCategoria, titulo, texto, imageUrl) => {
    //console.log("Adicionando conteúdo:",nomeCategoria,titulo,texto,imageUrl);
    try {
      // Referência ao documento principal de categorias
      const categoriasDocRef = doc(db, "frases", "categorias");

      // Obtém os dados atuais do documento
      const categoriasDoc = await getDoc(categoriasDocRef);

      if (categoriasDoc.exists()) {
        // Obtenha os dados atuais das categorias
        const categoriasData = categoriasDoc.data();

        // Certifique-se de que a categoria existe
        if (categoriasData[nomeCategoria]) {
          // Adicione o novo conteúdo à categoria
          const novoIndice = Object.keys(categoriasData[nomeCategoria]).length;
          categoriasData[nomeCategoria][novoIndice] = {
            titulo,
            texto,
            imageUrl,
            categoria: nomeCategoria,
          };

          // Atualize os dados no Firestore
          await setDoc(categoriasDocRef, categoriasData);

          // Atualize o estado local, se necessário
          // Isso depende da lógica específica do seu aplicativo
        } else {
          console.error("Categoria não encontrada:", nomeCategoria);
        }
      }
    } catch (error) {
      console.error("Erro ao adicionar conteúdo:", error);
      // Trate o erro conforme necessário
    }
  };

  useEffect(() => {
    async function loadUser() {
      const storageUser = localStorage.getItem("@usuarios_salvos");

      if (storageUser) {
        setUser(JSON.parse(storageUser));

        const docRef = doc(db, "admins", JSON.parse(storageUser).uid);
        const docDados = await getDoc(docRef);

        if (docDados.exists()) {
          setLoading(false);
          navigate("/painel");
        } else {
          toast.error("Apenas administradores podem acessar esta página.");
          setLoading(false);
          navigate("/");
        }
      } else {
        setLoading(false);
      }
    }

    loadUser();
  }, []);

  // Buscar categorias
  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        // Referência ao documento "categorias" dentro da coleção "frases"
        const categoriaRef = doc(db, "frases", "categorias");

        // Obtém os dados atuais do documento
        const categoriaDoc = await getDoc(categoriaRef);

        if (categoriaDoc.exists()) {
          // Obtenha os dados atuais
          const categoriasData = categoriaDoc.data();

          // Mapeia apenas os nomes das categorias
          const categoriasArray = Object.keys(categoriasData).map(
            (nomeCategoria) => ({
              nomeCategoria,
            })
          );

          setCategorias(categoriasArray);
        }
      } catch (error) {
        console.log("Erro ao buscar dados do Firestore:", error);
      }
    };

    fetchCategorias();
  }, []);

  return (
    <Context.Provider
      value={{
        signed: !!user,
        user,
        setUser,
        logar,
        logOut,
        loadingAuth,
        storageUser,
        adicionarCategoria,
        adicionarConteudo,
        categorias,
      }}
    >
      {children}
    </Context.Provider>
  );
}

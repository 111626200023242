import { Link } from "react-router-dom";
import "./styles.css";
import AdicionarConteudo from "../../components/adicionarConteudo";
import Header from "../../components/Header/Header";

export default function ScreenAdicionarConteudo() {
  return (
    <div className="container">
      <div className="headerWrapper">
        <Header />
      </div>
      <div className="content">
        <AdicionarConteudo />
      </div>
    </div>
  );
}

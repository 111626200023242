import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ContextProvider from "../Context/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Private from "./private";
import Error from "../pages/Error";
import Login from "../pages/Login";

import Painel from "../pages/Painel";
import ScreenCriarCategoria from "../pages/CriarCategoria/ScreenCriarCategoria";
import ScreenAdicionarConteudo from "../pages/AdicionarConteudo/ScreenAdicionarConteudo";
import ScreenPublicacoes from "../pages/Publicacoes/ScreenPublicacoes";

export default function RoutesApp() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <ToastContainer outClose={3000} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/painel"
            element={
              <Private>
                <Painel />
              </Private>
            }
          />

          <Route
            path="/criar-categoria"
            element={
              <Private>
                <ScreenCriarCategoria />
              </Private>
            }
          />

          <Route
            path="/publicar"
            element={
              <Private>
                <ScreenAdicionarConteudo />
              </Private>
            }
          />

          <Route
            path="/publicacoes"
            element={
              <Private>
                <ScreenPublicacoes />
              </Private>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </ContextProvider>
    </BrowserRouter>
  );
}

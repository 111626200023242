import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD8wcnNMX99L0PuvVjcUyZqQcZEbOeUZek",
  authDomain: "app-o-43bb1.firebaseapp.com",
  projectId: "app-o-43bb1",
  storageBucket: "app-o-43bb1.appspot.com",
  messagingSenderId: "715624516368",
  appId: "1:715624516368:web:7665f6a5aa9e2047527395",
  measurementId: "G-7BFP38398Z",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
//const analytics = getAnalytics(app);

export { auth, db, storage };

import React from "react";

export default function Error() {
  return (
    <div>
      <center>
        <br /> <br /> <br />
        <h1>OPS! NÃO ENCONTRAMOS ESSA PÁGINA</h1>
      </center>
    </div>
  );
}

import React, { useState, useContext } from "react";
import "./styles.css";
import { Context } from "../Context/Auth";
import { toast } from "react-toastify";

export default function CriarCategoria() {
  const { adicionarCategoria } = useContext(Context);
  const [nomeCategoria, setNomeCategoria] = useState("");

  const handleAdicionar = async (e) => {
    e.preventDefault();

    if (nomeCategoria.trim() === "") {
      toast.warning("Por favor, preencha o nome da categoria!");
      return;
    }

    try {
      // Chame a função adicionarCategoria do contexto, passando o nome da categoria
      await adicionarCategoria(nomeCategoria.trim());

      setNomeCategoria("");
      toast.success("Categoria adicionada!");
    } catch (error) {
      console.error("Erro ao adicionar categoria:", error);
      toast.error("Erro ao adicionar categoria. Por favor, tente novamente.");
    }
  };

  return (
    <div>
      <form className="form formSmall">
        <h2 className="title">Criar nova categoria</h2>
        <div className="boxInput">
          <label>Nome categoria</label>
          <input
            placeholder="Nome da categoria"
            maxLength={60}
            value={nomeCategoria}
            onChange={(e) => setNomeCategoria(e.target.value)}
          />
        </div>

        <button className="buttonForm" onClick={handleAdicionar}>
          Adicionar
        </button>
      </form>
    </div>
  );
}
